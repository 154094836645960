import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components';
import "react-alice-carousel/lib/alice-carousel.css"
import HouseMap from './HouseMap';
import DinamycCarousel from './DinamycCarousel';



const meterTerrainIcon = require('../icons/metered.svg').default
const meterConstructionIcon = require('../icons/construction.svg').default
const kitchenIcon = require('../icons/kitchen.svg').default
const bathIcon = require('../icons/bath.svg').default
const bedIcon = require('../icons/bed.svg').default

const CustomIcon = styled.img`
    height: 45px;
    width: 45px;
`



const CarouselContainer = styled.div`
    height: calc(100vh - 60px);
    max-height: 1100px;
    position: relative;
    overflow: hidden;
    display:flex;
    justify-content: center;
    align-items: center;
`
const SliderWrapper = styled.div`
  width: 90vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;


const DataContainer = styled.div`
    //height: 1100px; //Don't miss delete this
    display:flex;
    justify-content: center;
`
const DataWrapper = styled.div`
    width: 80vw;
    border: 3px solid #000;
    border-radius: 10px;
    background: #e6e9ed;
`

const HouseTitle = styled.h1`
    color: #712943;
    font-size: 2.5rem;
    padding: 10px;
`
const HouseLocation = styled.h2`
    font-size: 1.5rem;
    padding: 10px;
    color: #7a7c7d;

`
const HousePrice = styled.h3`
    font-size: 1.5rem;
    padding-left: 10px;
    color: #7a7c7d;
`

const HouseSpecs = styled.div`
    padding:10px;
    border-top: 2px solid #712943;
`
const SpecsCont = styled.div`
    display: inline-block;
`
const SpecContainer = styled.div`
    padding: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`
const SpecValue = styled.h3`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    padding:5px;
`

const SpecDataContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const HouseDescription = styled.div`
    padding:10px;
`

const HouseMapContainer = styled.div`
    width: 100%;
    padding: 15px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
`

const SaleContainer = styled.div`
    background: rgb(218,218,218);
    background: linear-gradient(180deg, rgba(218,218,218,1) 8%, rgba(255,255,255,1) 100%);
    `



const Sale = () => {
    const { id } = useParams();
    // <HeroImage src={imageOne} onDragStart={handleDragStart} />,
    // <HeroImage src={imageTwo} onDragStart={handleDragStart} />,
    // <HeroImage src={imageThree} onDragStart={handleDragStart} />

    const [data, setData] = useState({})
    useEffect(() => {
        async function getHouseData() {
            try {
                const res = await axios.get('https://ingenierosyarquitectos.net/api/sales/' + id);
                console.log(res)
                setData(res.data)
            } catch (error) {
                console.log(error);
            }
        }
        getHouseData()

    }, [])



    return (

        <SaleContainer>
            <CarouselContainer>
                <SliderWrapper>
                    {
                        data.images ? (
                            <DinamycCarousel imagesArray={data.images}></DinamycCarousel>
                        ) : (
                            <h1>No hay imagenes para mostrar</h1>
                        )
                    }

                </SliderWrapper>
            </CarouselContainer>
            <DataContainer>
                <DataWrapper>
                    <HouseTitle>
                        {data.title}
                    </HouseTitle>
                    <HouseLocation>
                        {data.location}
                    </HouseLocation>
                    <HousePrice>
                        {data.price}
                    </HousePrice>
                    <HouseSpecs>
                        <HouseTitle>Caracteristicas</HouseTitle>
                        {
                            data.especs ? (
                                data.especs.map((spec) => (
                                    <SpecsCont key={spec._id}>
                                        {
                                            (spec.name === 'livingRooms' ?
                                                <SpecContainer>
                                                    <CustomIcon src={bedIcon} />
                                                    <SpecDataContainer>
                                                        <SpecValue>Recamaras</SpecValue>
                                                        <SpecValue>{spec.value}</SpecValue>
                                                    </SpecDataContainer>
                                                </SpecContainer>
                                                :
                                                (spec.name === 'bathRooms'?
                                                <SpecContainer>
                                                    <CustomIcon src={bathIcon} />
                                                    <SpecDataContainer>
                                                        <SpecValue>Baños</SpecValue>
                                                        <SpecValue>{spec.value}</SpecValue>
                                                    </SpecDataContainer>
                                                </SpecContainer>
                                                :spec.name === 'metersOfConstuction'?(
                                                    <SpecContainer>
                                                    <CustomIcon src={meterConstructionIcon} />
                                                    <SpecDataContainer>
                                                        <SpecValue>Metros de constructión</SpecValue>
                                                        <SpecValue>{spec.value}</SpecValue>
                                                    </SpecDataContainer>
                                                </SpecContainer>
                                                ):spec.name === 'metersOfTerrain'?(
                                                    <SpecContainer>
                                                    <CustomIcon src={meterTerrainIcon} />
                                                    <SpecDataContainer>
                                                        <SpecValue>Metros de terreno</SpecValue>
                                                        <SpecValue>{spec.value}</SpecValue>
                                                    </SpecDataContainer>
                                                </SpecContainer>
                                                ):(<SpecContainer>
                                                    <CustomIcon src={kitchenIcon} />
                                                    <SpecDataContainer>
                                                        <SpecValue>Cocinas</SpecValue>
                                                        <SpecValue>{spec.value}</SpecValue>
                                                    </SpecDataContainer>
                                                </SpecContainer>)
                                                


                                                )
                                            
                                            )
                                        }
                                    </SpecsCont>
                                ))
                            ) : (
                                <h1>No hay valores</h1>
                            )
                        }
                    </HouseSpecs>
                    <HouseDescription>
                        <HouseTitle>Descripción</HouseTitle>
                        <HousePrice>{data.description}</HousePrice>
                    </HouseDescription>
                    <HouseMapContainer>
                        {
                            data.locationForMap && data.locationForMap.lat && data.locationForMap.lng ?
                                (
                                    <HouseMap
                                        cords={data.locationForMap}
                                        popuptitle={data.title}
                                    ></HouseMap>
                                ) : (
                                    <h1>Mapa Perdido</h1>
                                )
                        }
                    </HouseMapContainer>
                </DataWrapper>
            </DataContainer>
        </ SaleContainer>

    )
}

export default Sale
