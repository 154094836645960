import Dropdown from "./components/Dropdown";
import MainSlider from "./components/MainSlider";
import Navbar from "./components/Navbar";
import SliderData from "./data/sliderData";
import GlobalStyle from "./globalStyles";
import { useState } from "react";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Sales from "./components/Sales";
import Sale from "./components/Sale"
import Footer from './components/Footer'
import Rentals from "./components/Rentals";
import ContactUs from "./components/ContactUs";

function App() {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const toggle = () => {
    setIsMenuOpened(!isMenuOpened);
  };
  return (
    <Router>
      <GlobalStyle></GlobalStyle>
      <Navbar toggle={toggle}></Navbar>
      <Dropdown isMenuOpened={isMenuOpened} toggle={toggle}></Dropdown>
      <Switch>
        <Route exact path='/'>
          <MainSlider slides={SliderData} />
        </Route>
        <Route exact path='/sales'>
          <Sales></Sales>
        </Route>
        <Route path='/sales/:id'>
          <Sale></Sale>
        </Route>
        <Route exact path='/rentals'>
          <Rentals></Rentals>
        </Route>


        <Route exact path='/contacto'>
          <ContactUs></ContactUs>
        </Route>
      </Switch>
      <Footer />
      
    </Router>
  );
}

export default App;
