import React from 'react'
import styled from 'styled-components'

const ErrorContainer = styled.div`
    width: 100vw;
    height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    align-items: center;

`

const ErrorMessage = styled.h1`
    font-size: 2.5rem;
    color: #000;
`


const ErrorComponent = (props) => {
    const {message} = props
    return (
        <ErrorContainer>
            <ErrorMessage>{message}</ErrorMessage>
        </ErrorContainer>
    )
}

export default ErrorComponent
