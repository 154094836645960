import React, { useEffect, useState } from 'react'
import AliceCarousel from 'react-alice-carousel'
import styled from 'styled-components';

const HeroImage = styled.img`
  position: absolute;
  top: 0;
  left: 0%;
  width: 100vw;
  height: 90vh;
  object-fit: cover;
`;
const handleDragStart = (e) => e.preventDefault();

const DinamycCarousel = (props) => {
    
    // if(res.data.images){
    //     const dataImages = res.data.images.map((imagen) => 
    //         <HeroImage src={imagen.path} onDragStart={handleDragStart} key={imagen._id}/>
    //     )
    //     setImages(dataImages)
    // }

    const {imagesArray} = props
    const [images, setImages] = useState([])
    console.log('propiedades carrusel', props)
    useEffect(()=>{
        if(imagesArray){
            const imagesForSlider = imagesArray.map((imagen)=>(
                <HeroImage src={imagen.path} onDragStart={handleDragStart} key={imagen._id}/>
            ))
            setImages(imagesForSlider)
        }

    }, [])
    return (
        <>
            <AliceCarousel mouseTracking items={images} autoHeight keyboardNavigation autoPlay autoPlayInterval={2000} infinite/>
        </>
    )
}

export default DinamycCarousel
