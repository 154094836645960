const imageOne = require("../images/house1.jpg").default;
const imageTwo = require("../images/house2.jpg").default;
const imageThree = require("../images/house3.jpg").default;
const imageFour = require("../images/house4.jpg").default;

export const SliderData = [
  {
    tittle: "Casa en los angeles",
    price: "6,000,000 Mxn",
    path: "/sales",
    label: "Ver casa",
    image: imageOne,
    alt: "Casa en venta",
  },
  {
    tittle: "Casa en Miami",
    price: "15,000 / mes",
    path: "/rentals",
    label: "Ver casa",
    image: imageTwo,
    alt: "Casa en renta",
  },
  {
    tittle: "Casa en los angeles",
    price: "6,000,000 Mxn",
    path: "/sales",
    label: "Ver casa",
    image: imageThree,
    alt: "Casa en venta",
  },
  {
    tittle: "Casa en Miami",
    price: "15,000 / mes",
    path: "/rentals",
    label: "Ver casa",
    image: imageFour,
    alt: "Casa en renta",
  },
];

export default SliderData;
