import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { menuData } from "../data/menuData";
import { ButtonContactUs } from "./Button";
import { FaBars } from "react-icons/fa";
const logo = require("../images/ing_y_arq_logo.svg").default;

const Nav = styled.nav`
  height: 60px;
  background: #712943;
  display: flex;
  justify-content: space-between;
`;

const NavCss = css`
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
`;

const LogoContainer = styled(Link)`
  background: #cfc4c8;
  border-radius: 10px;
  ${NavCss}
`;

const Logo = styled.img`
  height: 50px;
  width: auto;
`;

const MenuBars = styled(FaBars)`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    height: 40px;
    width: 40px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-50%, 25%);
  }
  //CgMenuLeft
`;
const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -48px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const NavMenuItem = styled(Link)`
  ${NavCss}
  &:hover {
    border-bottom: 1px solid #fff;
  }
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Navbar = ({ toggle }) => {
  return (
    <Nav>
      <LogoContainer to="/">
        <Logo src={logo} />
      </LogoContainer>
      <MenuBars onClick={toggle} />
      <NavMenu>
        {menuData.map((item, index) => (
          <NavMenuItem to={item.link} key={index}>
            {item.title}
          </NavMenuItem>
        ))}
      </NavMenu>
      <BtnContainer>
        <ButtonContactUs primary="false" to='/contacto' cords = {{
          lat: 19.710346,
          lng: -98.967702
        }} popuptitle = {'Oficinas Ingenieros y arquitectos ++E'}>Contacto</ButtonContactUs>
      </BtnContainer>
    </Nav>
  );
};

export default Navbar;
