import styled from "styled-components";
import { Link } from "react-router-dom";

export const ButtonContactUs = styled(Link)`
  background: ${({ primary }) => (primary ? "#fff" : "#712943")};
  color: ${({ primary }) => (primary ? "#712943" : "#fff")};
  white-space: nowrap;
  outline: none;
  border: none;
  min-width: 100px;
  max-width: 200px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border-radius: 10px;
  height: 80%;
  min-height: 30px;

  &:hover {
    transform: translateY(-2px);
  }
`;
