import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import '../styles.css'
import IconPinMap from './IconPinMap'


const HouseMap = (props) => {
    console.log(props)
    const { cords } = props
    const {popuptitle} = props
    console.log(props)




    return (
        <MapContainer center={[cords.lat ? cords.lat : 19.710346, cords.lng ? cords.lng : -98.967702]} zoom={15} scrollWheelZoom={false}>
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[cords.lat ? cords.lat : 19.710346, cords.lng ? cords.lng : -98.967702]} icon={IconPinMap}>
                <Popup>
                    {popuptitle?popuptitle:'Oficinas Ing y Arq ++E'}
                </Popup>
            </Marker>
        </MapContainer>
    )
}

export default HouseMap
