import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import { Card, CardActionArea, CardContent, CardMedia } from '@material-ui/core'
//import styled from 'styled-components'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { IoBedOutline } from 'react-icons/io5'
import { FaBath } from 'react-icons/fa'
import ErrorComponent from './ErrorComponent'

const imageOne = require('../images/ing_y_arq_logo.svg').default
const meterTerrainIcon = require('../icons/metered.svg').default


const CustomIcon = styled.img`
    height: 30px;
    width: 30px;
`

const FaBathStyled = styled(FaBath)`
    height: 30px;
    width: 30px;
`

const useStyles = makeStyles({
    root: {
        maxWidth: 450,
        minWidth: 300,
    },
    media: {
        height: 140,
    },
    cont: {
        padding: 10,
        alignContent: 'space-between',
    },
    parentCont: {
        minHeight: 'calc(100vh - 60px)'
    }
})

const SpecsContainer = styled.div`
    //padding: 10px;
    //max-width: 225px;
    //width: 70px;
    //height: 50px;
    display: flex;
    //display: flex;
    justify-content: flex-start;
`
const SpecsCont = styled.div`
    display: inline-block;
`
const SpecContainer = styled.div`
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`
const SpecValue = styled.h3`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    padding:5px;
`

/* const IconStyled = styled.img`
    position: absolute;
    top: 0;
    left: 0%;
    width: 50vw;
    height: 100vh;
    object-fit: cover;
` */
const PropertiTitle = styled.h1`
    color: #712943;
    font-size: 1.5rem;
`
const PropertiPrice = styled.h3`
    color: #712943;
    font-size: 1rem;
`
const IoBedOutlineStyled = styled(IoBedOutline)`
    height: 30px;
    width: 30px;
`

const Sales = () => {
    const [sales, setSales] = useState([])
    const [isOk, setIsOk] = useState(false)
    const personStyles = useStyles()
    useEffect(() => {
        async function getSales() {
            try {
                const res = await axios.get('https://ingenierosyarquitectos.net/api/sales');
                console.log(res)
                setSales(res.data)
                setIsOk(true)
            } catch (error) {
                console.log(error);
                setIsOk(false)
            }
        }
        getSales();

    }, [])
    return (
        <>
            <Grid container className={personStyles.parentCont}>

                {
                    isOk && sales.length > 0 ? (
                        sales.map((propertie) => (
                            <Grid item alignContent={'space-between'} className={personStyles.cont} key={propertie._id}>
                                <Card className={personStyles.root}>
                                    <CardActionArea component={Link} to={`/sales/${propertie._id}`} cords4Map={propertie.locationForMap}>
                                        <CardMedia
                                            className={personStyles.media}
                                            image={
                                                propertie.images && propertie.images[0] ?
                                                    propertie.images[0].path
                                                    : imageOne
                                            }
                                            tittle='Casa en venta' />
                                        <CardContent>
                                            <PropertiTitle>{propertie.title}</PropertiTitle>
                                            <PropertiPrice>{propertie.price}</PropertiPrice>
                                            <SpecsContainer>

                                                {propertie.especs.map(
                                                    (espec) => (
                                                        espec.name === 'livingRooms' ? (
                                                            <SpecsCont key={espec._id}>
                                                                <SpecContainer>
                                                                    <IoBedOutlineStyled />
                                                                    <SpecValue>{espec.value}</SpecValue>
                                                                </SpecContainer>
                                                            </SpecsCont>
                                                        ) : (
                                                            espec.name === 'bathRooms' ?
                                                                <SpecsCont key={espec._id}>
                                                                    <SpecContainer>
                                                                        <FaBathStyled />
                                                                        <SpecValue>{espec.value}</SpecValue>
                                                                    </SpecContainer>
                                                                </SpecsCont>
                                                                :
                                                                <SpecContainer key={espec._id}>
                                                                    <CustomIcon src={meterTerrainIcon} />
                                                                    <SpecValue>{espec.value}</SpecValue>
                                                                </SpecContainer>
                                                        )
                                                    )
                                                )}
                                            </SpecsContainer>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))
                    ) : (sales.message ? (
                        <ErrorComponent message = {sales.message}></ErrorComponent>
                    ) : (
                        <ErrorComponent message = {'No hay propiedades para mostrar'}></ErrorComponent>
                    ))
                }

            </Grid>
        </>
    )
}

export default Sales
