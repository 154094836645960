import L from 'leaflet'

const IconPinMap = L.icon({
    iconUrl: require('../icons/Map-Pin.svg').default,
    iconRetinaUrl: require('../icons/Map-Pin.svg').default,
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    iconSize:[50,50],
    className: "leaflet-venue-icon"
})

export default IconPinMap
