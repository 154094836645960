import React from 'react'
import HouseMap from './HouseMap'
import styled from 'styled-components'


const MapContainer = styled.div`
    width: 100%;
    padding: 15px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    z-index:2;
`

const DataContainer = styled.div`
    //height: 1100px; //Don't miss delete this
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`
const DataWrapper = styled.div`
    width: 80vw;
    border: 2px solid #000;
    border-radius: 10px;
    background: #e6e9ed;
`
const TitleStyled = styled.h1`
    display: flex;
    align-items: flex-start;
    font-size: 2.5rem;
    color: #712943;
    padding: 20px;
`
const SubtitleStyled = styled.h1`
    display: flex;
    align-items: flex-start;
    font-size: 1.5rem;
    color: #7a7c7d;
    padding-left: 20px;
    padding-top: 10px;
    //padding-bottom: 10px;
`
const TextStyled = styled.p`
    display: flex;
    align-items: flex-start;
    font-size: 1.2rem;
    color: #712943 ;
    padding-left: 20px;
`
const WhatsButton = styled.a`
    background: ${({ primary }) => (primary ? "#fff" : "#712943")};
  color: ${({ primary }) => (primary ? "#712943" : "#fff")};
  white-space: nowrap;
  outline: none;
  border: none;
  min-width: 100px;
  max-width: 200px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border-radius: 10px;
  height: 80%;
  min-height: 30px;
    
  &:hover {
    transform: translateY(-2px);
  }
`
const ButtonContainer = styled.div`
    cursor: pointer;
    padding-left: 20px;
    padding-top: 10px;
`

//https://wa.me/5215537479082
const ContactUs = () => {
    return (
        <>
            <DataContainer>
                <DataWrapper>
                    <TitleStyled>Contacto</TitleStyled>
                    <SubtitleStyled>Correo electronico</SubtitleStyled>
                    <TextStyled>ing_amo@hotmail.com</TextStyled>
                    <SubtitleStyled>Número telefonico</SubtitleStyled>
                    <TextStyled>55-4978-5583</TextStyled>
                    <ButtonContainer>
                        <WhatsButton href="https://wa.me/5215520489112">Whatsapp</WhatsButton>
                    </ButtonContainer>
                    
                    <SubtitleStyled>Ubicación</SubtitleStyled>
                    <TextStyled>Hidalgo 1-A Privada Hidalgo de Felipe Villanueva Tecámac, Edo Méx, C.P. 55740 (A un costado del palacio municipal)</TextStyled>
                    <MapContainer>
                        <HouseMap cords={{
                            lat: 19.710346,
                            lng: -98.967702
                        }} popuptitle={'Oficinas Ingenieros y arquitectos ++E'}></HouseMap>
                    </MapContainer>


                </DataWrapper>
            </DataContainer>
        </>
    )
}

export default ContactUs
