import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const FooterContainer = styled.div`
    padding: 80px 60px;
    //background: #000;
    background-image: linear-gradient( 110.3deg,  rgba(73,93,109,1) 4.3%, rgba(49,55,82,1) 96.7% );
`

const FooterLinksContainer = styled.div`
    width: 100%;
    //max-width: 1000px;
    display: flex;
    justify-content: center;

    @media screen and (max-width:820px){
        padding-top: 32px;
    }
`

const FooterWrapper = styled.div`
    display: flex;
    @media screen and (max-width:820px){
        flex-direction: column;
    }
`

const FooterLinksItems = styled.div`
    display: flex;
    flex-direction: column;
    margin: 16px;
    align-items: center;
    text-align: left;
    width: 190px;
    box-sizing: border-box;
    color: #fff;
    //border: 1px solid #000;
    @media screen and (max-width:420px){
        margin: 0;
        padding:10px;
        width: 100%;
    }
`

const FooterTitle = styled(Link)`
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;
    cursor:pointer;
    &::hover{
        border-bottom: 2px solid #fff;
    }
`




const Footer = () => {
    return (
        <FooterContainer>
            <FooterLinksContainer>
                <FooterWrapper>
                    <FooterLinksItems>
                        <FooterTitle to="/">Home</FooterTitle>
                    </FooterLinksItems>
                    <FooterLinksItems>
                        <FooterTitle to="/rentals">Propiedas en renta</FooterTitle>
                    </FooterLinksItems>
                    <FooterLinksItems>
                        <FooterTitle to="/sales">Propiedades en venta</FooterTitle>
                    </FooterLinksItems>
                    <FooterLinksItems>
                        <FooterTitle to="/contacto" cords={{
                            lat: 19.710346,
                            lng: -98.967702
                        }} popuptitle={'Oficinas Ingenieros y arquitectos ++E'}>Contacto</FooterTitle>
                    </FooterLinksItems>

                </FooterWrapper>
            </FooterLinksContainer>
        </FooterContainer>
    )
}

export default Footer
