import React from "react";
import { FaTimes } from "react-icons/fa";
import styled from "styled-components";
import { menuData } from "../data/menuData";
import { ButtonContactUs } from "./Button";
import { Link } from "react-router-dom";

const DropdownContainer = styled.div`
  position: fixed;
  z-index: 200;
  width: 100%;
  height: 100%;
  background: #712943;
  color: #fff;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isMenuOpened }) => (isMenuOpened ? "1" : "0")};
  top: ${({ isMenuOpened }) => (isMenuOpened ? "0" : "-100%")};
  //left: ${({ isMenuOpened }) => (isMenuOpened ? "0" : "-100%")};
`;

const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;

const CloseIcon = styled(FaTimes)``;

const DropdownWrapper = styled.div``;

const DropdownMenu = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 80px);
  text-align: center;
  margin-bottom: 4rem;

  @media screen and (max-width: 480px) {
    grid-template-rows: repeat(3, 60px);
  }
`;
const DropdownItem = styled(Link)`
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  font-size: 1.5rem;
  list-style: none;
  transition: 0.2s ease-in-out;
  &:hover {
    background: #fff;
    color: #712943;
  }
`;

const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Dropdown = ({ isMenuOpened, toggle }) => {
  return (
    <DropdownContainer isMenuOpened={isMenuOpened} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <DropdownWrapper>
        <DropdownMenu>
          {menuData.map((item, index) => (
            <DropdownItem to={item.link} key={index}>
              {item.title}
            </DropdownItem>
          ))}
        </DropdownMenu>
        <BtnWrapper>
          <ButtonContactUs primary="false" to='/contacto' cords={{
            lat: 19.710346,
            lng: -98.967702
          }} popuptitle={'Oficinas Ingenieros y arquitectos ++E'}>Contacto</ButtonContactUs>
        </BtnWrapper>
      </DropdownWrapper>
    </DropdownContainer>
  );
};

export default Dropdown;
